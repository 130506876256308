import React from 'react'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import Table from '../../components/Table'
import {
  NUM_COUNTRIES_IN_REG,
  //GBL_NUM_COUNTRIES,
  LABELS_SP21_API
} from '../../utils/const'
//import { wrangleSPResponseTableData } from '../../utils/helpers'
import { useTranslation } from 'react-i18next'
import { group } from 'd3-array'

const SP21Table = ({ regions, region }) => {
  const { t } = useTranslation()
  const numCountries = NUM_COUNTRIES_IN_REG[region] //number of countries
  // const allYears = []
  // const wrangleSPResponseTableData = (regions, numCountries) => {
  //   const allCategories = []
  //   const allData = Object.entries(regions)
  //     .map(([key, value]) => {
  //       const keysToExclude = [
  //         'memberState',
  //         'iSO3',
  //         'id',
  //         'region',
  //         'regionType',
  //         'incomeGroup',
  //         'uNICEFRegion'
  //       ]

  //       if (!keysToExclude.includes(key) && value !== null) {
  //         const year = key.slice(-4)
  //         const category = key.slice(0, -4)
  //         //if (category !== 'nDNRNoResponse') {
  //         allCategories.push(category)
  //         // }
  //         allYears.push(year)
  //         return { year, category, value }
  //       } else return null
  //     })

  //     .filter((item) => item)
  //   const categories = Array.from(new Set(allCategories))
  //   //console.log('cat', categories)
  //   //const years = Array.from(new Set(allYears))
  //   //borrowing this from chart wrangling so we can total yes and no responses
  //   // const rowsByYear = years.map((year) => {
  //   //   const responseData = allData
  //   //     .filter((d) => d.year === year)
  //   //     .reduce((acc, d) => {
  //   //       acc[d.category] = d.value
  //   //       acc['year'] = year
  //   //       return acc
  //   //     }, {})
  //   //   return responseData
  //   // })
  //   // const getYesNoTotal = (data, year) => {
  //   //   const row = data.find((d) => d.year === year)
  //   //   return row.yes + row.no
  //   // }

  //   const formattedData = categories.map((category) => {
  //     //const categoriesExcluded = ['noData', 'noResponse', 'notRelevant']

  //     const catData = allData
  //       .filter((d) => d.category === category)
  //       .reduce((acc, d) => {
  //         // const val = categoriesExcluded.includes(d.category)
  //         //   ? numCountries
  //         //   : getYesNoTotal(rowsByYear, d.year)
  //         acc[d.year] = `${d.value} ${t('out of')} ${numCountries}`
  //         acc['year'] = d.year
  //         return acc
  //       }, {})
  //     return Object.assign(
  //       {
  //         category: t(LABELS_SP21[category])
  //       },
  //       catData
  //     )
  //   })

  // const totals = years.map((y) => {
  //   const tote = getYesNoTotal(rowsByYear, y)
  //   return {
  //     category: t('total with response'),
  //     [y]: `${tote} ${t('out of')} ${numCountries}`,
  //     year: y
  //   }
  // })

  //return totals.concat(formattedData)
  // return formattedData
  //  }
  const BASELINE_YEAR = 2021
  const dataMap = group(regions, (d) => d.DIMENSION2)
  const dataByResponse = Array.from(dataMap.values())

  const sorted = dataByResponse
    .sort((a, b) => (a[0].DIMENSION2 === 'YES' ? 1 : -1))
    .sort((a, b) => (a[0].DIMENSION2 === 'ND_NR_NORESPONSE' ? 1 : -1))

  let yrs = []
  const sp21Data = sorted.map((s) => {
    return s.reduce((acc, d) => {
      if (d.YEAR !== BASELINE_YEAR) yrs.push(d.YEAR)
      acc[d.YEAR === BASELINE_YEAR ? 'Baseline' : d.YEAR] = `${d.NUMERATOR} ${t(
        'out of'
      )} ${d.DENOMINATOR} `
      acc['category'] = t(LABELS_SP21_API[d.DIMENSION2])
      return acc
    }, {})
  })

  const years = Array.from(new Set(yrs))

  // data with filled in blanks
  sp21Data.forEach((d, i) => {
    years.map((y) => {
      return d.hasOwnProperty(y)
        ? d
        : (d[y] = `0 ${t('out of')} ${numCountries}`)
    })
  })

  //const sp21Data = wrangleSPResponseTableData(regions, numCountries)

  const data = React.useMemo(() => sp21Data, [sp21Data])

  const yearHeaders = years?.map((year) => {
    return {
      Header: `${year}`,
      accessor: `${year}`
    }
  })
  const columns = React.useMemo(() => {
    const baseHeader = [
      {
        Header: t('number of countries'),
        accessor: 'category' // accessor is the "key" in the data
      },
      {
        Header: `${t('Baseline')} (${BASELINE_YEAR})`,
        accessor: 'Baseline' // accessor is the "key" in the data
      }
    ]
    return baseHeader.concat(yearHeaders)
  }, [yearHeaders, t])
  return (
    <Table
      data={data}
      columns={columns}
      noBoldTopRow={true}
      description={t(
        'A table that lists the proportion of countries with legislation in place that is supportive of immunization as a public good.'
      )}
    />
  )
}

export default SP21Table
